import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementStatus,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';

// Definition of the badge's color for the discussion status
export const statuses: StatusBadgeArgument[] = [{ value: 'OPEN', color: BadgeColor.ORANGE }];

export class DiscussionRow {
  id: string;
  subjectMatter: string;
  discussions: string;
  clauses: string;
  tenantComments: string;
  landlordResponse: string;
  status: string;
  subjectStatus: string;

  constructor(discussion: any) {
    this.id = discussion.id;
    this.subjectMatter = discussion.subjectMatter;
    this.discussions = discussion.discussions;
    this.clauses = discussion.clauses;
    this.tenantComments = discussion.tenantComments!;
    this.landlordResponse = discussion.landlordResponse;
    this.status = discussion.status;
    this.subjectStatus = discussion.subjectStatus;
  }
}

/*
 * Definition of the column (names, format...)
 */
export const discussionColumnConfig = (): CustomColumnProps[] => {
  return [
    {
      field: 'subjectMatter',
      header: 'Subject Matter',
      filterPlaceholder: 'Search by Subject Matter',
      filter: true,
      filterField: 'subjectMatter',
      sortable: true,
    },
    {
      field: 'discussions',
      header: 'Discussions',
      filterPlaceholder: 'Search by Discussions',
      filter: true,
      filterField: 'discussions',
      sortable: true,
      body: (row: any) => {
        return row.discussions.map((discussion: string) => <p>{discussion}</p>);
      },
    },
    {
      field: 'clauses',
      header: 'Clauses',
      filterPlaceholder: 'Search by Clauses',
      filter: true,
      filterField: 'clauses',
      sortable: true,
      body: (row: any) => {
        return row.clauses.map((clause: string) => <p>{clause}</p>);
      },
    },
    {
      field: 'tenantComments',
      header: 'Tenant Comments/Edits',
      filterPlaceholder: 'Search by Tenant Comments',
      filter: true,
      filterField: 'tenantComments',
      sortable: true,
      body: (row: any) => {
        return row.tenantComments.map((comment: string) => <p>{comment}</p>);
      },
    },
    {
      field: 'landlordResponse',
      header: 'Landlord Response',
      filterPlaceholder: 'Search by Landlord Response',
      filter: true,
      filterField: 'landlordResponse',
      sortable: true,
    },
    {
      field: 'subjectStatus',
      header: 'Status',
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'status',
      sortable: true,
      body: (row: any) => filterElementStatusTemplate(row.subjectStatus, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
  ];
};
