import { useEffect, useState } from 'react';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementStatusTemplate } from 'atoms/TableReactPrime/columnTemplates';
import { DiscussionRow, discussionColumnConfig, statuses } from './DiscussionTableConfig';
import './DiscussionsTable.scss';

const DiscussionsTable = (): JSX.Element => {
  const [columnConfig, setColumnConfig] = useState<CustomColumnProps[]>(discussionColumnConfig());

  useEffect(() => {
    setColumnConfig(discussionColumnConfig());
  }, []);

  const discussionsList = [
    {
      id: '1',
      subjectMatter: 'Provision Name 1',
      discussions: ['Discussion Topic 1', 'Discussion Topic 1.1', 'Discussion Topic 1.2'],
      clauses: ['Offer Letter clause 1', 'Offer Letter clause 1.1', 'Offer Letter clause 1.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'Noted',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '2',
      subjectMatter: 'Provision Name 2',
      discussions: ['Discussion Topic 2'],
      clauses: ['Offer Letter clause 2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '3',
      subjectMatter: 'Provision Name 3',
      discussions: ['Discussion Topic 3', 'Discussion Topic 3.1', 'Discussion Topic 3.2'],
      clauses: ['Offer Letter clause 3', 'Offer Letter clause 3.1', 'Offer Letter clause 3.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'Noted',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '4',
      subjectMatter: 'Provision Name 4',
      discussions: ['Discussion Topic 4', 'Discussion Topic 4.1', 'Discussion Topic 4.2'],
      clauses: ['Offer Letter clause 4', 'Offer Letter clause 4.1', 'Offer Letter clause 4.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'Noted',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '5',
      subjectMatter: 'Provision Name 5',
      discussions: ['Discussion Topic 5'],
      clauses: ['Offer Letter clause 5'],
      tenantComments: ['Noted'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '6',
      subjectMatter: 'Provision Name 6',
      discussions: ['Discussion Topic 6', 'Discussion Topic 6.1', 'Discussion Topic 6.2'],
      clauses: ['Offer Letter clause 6', 'Offer Letter clause 6.1', 'Offer Letter clause 6.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'Noted',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '7',
      subjectMatter: 'Provision Name 7',
      discussions: ['Discussion Topic 7', 'Discussion Topic 7.1', 'Discussion Topic 7.2'],
      clauses: ['Offer Letter clause 7', 'Offer Letter clause 7.1', 'Offer Letter clause 7.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'Noted',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '8',
      subjectMatter: 'Provision Name 8',
      discussions: ['Discussion Topic 8', 'Discussion Topic 8.1', 'Discussion Topic 8.2'],
      clauses: ['Offer Letter clause 8', 'Offer Letter clause 8.1', 'Offer Letter clause 8.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '9',
      subjectMatter: 'Provision Name 9',
      discussions: ['Discussion Topic 9', 'Discussion Topic 9.1', 'Discussion Topic 9.2'],
      clauses: ['Offer Letter clause 9', 'Offer Letter clause 9.1', 'Offer Letter clause 9.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '10',
      subjectMatter: 'Provision Name 10',
      discussions: ['Discussion Topic 10', 'Discussion Topic 10.1', 'Discussion Topic 10.2'],
      clauses: ['Offer Letter clause 10', 'Offer Letter clause 10.1', 'Offer Letter clause 10.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '11',
      subjectMatter: 'Provision Name 11',
      discussions: ['Discussion Topic 11', 'Discussion Topic 11.1', 'Discussion Topic 11.2'],
      clauses: ['Offer Letter clause 11', 'Offer Letter clause 11.1', 'Offer Letter clause 11.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
    {
      id: '12',
      subjectMatter: 'Provision Name 12',
      discussions: ['Discussion Topic 12', 'Discussion Topic 12.1', 'Discussion Topic 12.2'],
      clauses: ['Offer Letter clause 12', 'Offer Letter clause 12.1', 'Offer Letter clause 12.2'],
      tenantComments: ['Noted', 'Still needs work', 'Not upto the mark'],
      landlordResponse: 'qwewe',
      subjectStatus: 'OPEN',
      status: ['OPEN', 'OPEN', 'OPEN'],
    },
  ];

  const currentList: DiscussionRow[] = discussionsList.map((discussion: any) => new DiscussionRow(discussion));

  const [data, setData] = useState(currentList);

  // TODO
  const onRowReorder = (e: any) => {
    setData(e.value); // Update the data state with the new order
  };

  // TODO
  const [selectedRows, setSelectedRows] = useState([]);

  const onSelectionChange = (e: any) => {
    setSelectedRows(e.value);
  };

  const [expandedRows, setExpandedRows] = useState(null);

  // TODO
  const onRowExpand = (event: any) => {};

  // TODO
  const onRowCollapse = (event: any) => {};

  const rowExpansionTemplate = (row: any) => {
    const rowCount = row.discussions.length;
    let rowExpansion = [];

    for (let i = 0; i < rowCount; i++) {
      rowExpansion.push(
        <div className="row-expansion-template">
          <tr>
            <td></td>
            <td>
              <span>{row.discussions[i]}</span>
            </td>
            <td>
              <span>{row.clauses[i]}</span>
            </td>
            <td>
              <span>{row.tenantComments[i]}</span>
            </td>
            <td>
              <span>{row.landlordResponse}</span>
            </td>
            <td>{filterElementStatusTemplate(row.status[i], statuses)}</td>
          </tr>
        </div>,
      );
    }

    return rowExpansion;
  };

  const onRowToggle = (e: any) => {
    setExpandedRows(e.data);
  };

  const menuModel = [{ label: 'Merge', icon: 'pi pi-fw pi-angle-left', command: () => console.log('Merge') }];

  return (
    <TableReactPrime
      fetchContent={({ ...rest }) => {}}
      isFetching={false}
      content={data}
      columnConfig={columnConfig}
      showReorderingToggler={true}
      allowDragDrop={true}
      onRowReorder={onRowReorder}
      selectionMode="multiple"
      selectedRows={selectedRows}
      onSelectionChange={onSelectionChange}
      rowExpansion={true}
      expandedRows={expandedRows}
      onRowExpand={onRowExpand}
      onRowCollapse={onRowCollapse}
      rowExpansionTemplate={rowExpansionTemplate}
      onRowToggle={onRowToggle}
      menuModel={menuModel}
    />
  );
};

export default DiscussionsTable;
